import React from 'react'
import PropTypes from 'prop-types'

import { bpExtraLarge, bpLarge, bpMedium, bpSmall } from '@styles/variables.module.scss'

const breakPoints = [bpSmall, bpMedium, bpLarge, bpExtraLarge]

const buildWidthHeightSrc = (src, w, h) => {
  const queryString = `${w ? `w=${w}&` : ''}${h ? `h=${h}&` : ''}`
  return `${src}?${queryString}`
}

const getSpecificSize = (src, w, h) => {
  // CMS instructions are for users to upload images at 2x so we can target retina devices
  // On the component, we'll specify the 2x target size that we're expecting from CMS, and 1/2 it here for non-retina
  const src1X = buildWidthHeightSrc(src, w && Math.round(w / 2), h && Math.round(h / 2))
  const src2X = buildWidthHeightSrc(src, w, h)

  return (
    <React.Fragment>
      <source srcSet={`${src1X}fm=webp 1x, ${src2X}fm=webp 2x`} type="image/webp" />
      <source srcSet={`${src1X}fm=jpg&fl=progressive 1x, ${src2X}fm=jpg&fl=progressive 2x`} />
    </React.Fragment>
  )
}

const buildSrcSets = src => {
  return breakPoints.map((_, index) => {
    const isLast = index === breakPoints.length - 1
    const target = _.replace('px', '')
    const threshold = isLast ? breakPoints[breakPoints.length - 1] : target - 1
    return (
      <React.Fragment key={index}>
        <source srcSet={`${src}?w=${target}&fm=webp`} media={`(max-width: ${threshold}px)`} type="image/webp" />
        <source srcSet={`${src}?w=${target}&fm=jpg&fl=progressive`} media={`(max-width: ${threshold}px)`} />
      </React.Fragment>
    )
  })
}

const Picture = ({ src, alt, className, maxWidth, maxHeight }) => {
  const extraLarge = bpExtraLarge.replace('px', '')
  const responsiveSrcSets = buildSrcSets(src)
  const isResponsive = !maxWidth && !maxHeight
  const defaultSrc = isResponsive ? `${src}?w=${extraLarge}&` : buildWidthHeightSrc(src, maxWidth, maxWidth)

  return (
    <picture>
      {isResponsive && responsiveSrcSets}

      {!isResponsive && getSpecificSize(src, maxWidth, maxHeight)}

      <img className={className} src={`${defaultSrc}fm=jpg&fl=progressive`} alt={alt} />
    </picture>
  )
}

Picture.defaultProps = {
  alt: '',
}

Picture.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string,
  maxWidth: PropTypes.string,
  maxHeight: PropTypes.string,
}

export default Picture
