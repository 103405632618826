import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import styles from './PartnershipsPage.module.scss'
import Picture from '@components/Picture'
import { parseImage } from '@utils/contentful'
import { TransitionState } from 'gatsby-plugin-transition-link'
import Layout from '@components/Layout'
import Text from '@components/Text'
import GradientText from '@components/GradientText'
import {
  gradientColor1,
  gradientColor2,
  gradientColor3,
  gradientStop1,
  gradientStop2,
  gradientStop3,
} from '@styles/variables.module.scss'

const cx = classnames.bind(styles)

const PartnershipsPage = ({ data }) => {
  const { contentfulPartners } = data
  const [, setOpacityStyle] = useState()
  const handleOpacityChange = style => {
    setOpacityStyle(style)
  }

  let members = contentfulPartners.members.map(item => ({
    ...item,
    image: item.image && parseImage(item.image),
  }))

  return (
    <TransitionState>
      {({ transitionStatus } = {}) => {
        return (
          <Layout
            className={transitionStatus}
            onOpacityChange={handleOpacityChange}
            seo={contentfulPartners.header}
            smoothScroll={true}
          >
            <div className={cx('container')}>
              <div className={cx('hero-container')}>
                <div className={cx('hero-img-container')}>
                  <Picture
                    className={cx('hero-img')}
                    src={contentfulPartners.heroImage.file.url}
                    alt={contentfulPartners.heroImage.description}
                  />
                </div>
                <div className={cx('hero-text-container')}>
                  <Text tag="h1" type="h1" className={cx('dynamic-font-size')}>
                    {`Our`}
                  </Text>
                  <Text tag="h1" type="h1" className={cx('neg-margin-top', 'dynamic-font-size')}>
                    {`Partnerships`}
                  </Text>
                  <div className={cx('hero-small-text-container')}>
                    <Text tag="h3" type="h3" className={cx('hero-small-text', 'inline')}>
                      {`
                        Education and sports have the power to transform communities. 
                        `}
                    </Text>
                    <Text tag="h3" type="h3" className={cx('hero-small-text', 'italic', 'inline')}>
                      {`
                        "Group 1001 and our subsidiaries have a strong commitment to service and community transformation. 
                        Education and sports initiatives, coupled with impactful partnerships, allow Group 1001 to improve 
                        lives through positive change in our communities."
                        `}
                    </Text>
                    <Text tag="h3" type="h3" className={cx('hero-small-text')}>
                      {`
                        — Dan Towriss, CEO & President, Group 1001
                        `}
                    </Text>
                  </div>
                </div>
              </div>
              <div className={cx('partners-container')}>
                {members &&
                  members.map(item => {
                    if (item.image && item.description && item.name) {
                      return (
                        <div className={cx('min-margin')}>
                          <div className={cx('partner-container')}>
                            <div className={cx('logo-container')}>
                              <Picture
                                className={cx('partner-logo')}
                                src={item.image.url}
                                alt={item.image.description}
                              />
                            </div>
                            <GradientText
                              tag="h4"
                              className={cx('partner-title')}
                              type="h4"
                              colors={[gradientColor1, gradientColor2, gradientColor3]}
                              colorStops={[gradientStop1, gradientStop2, gradientStop3]}
                            >
                              {item.name}
                            </GradientText>
                            <Text className={cx('description')} tag="b1" type="b1">
                              {item.description.description}
                            </Text>
                            <div className={cx('links-container')}>
                              <a
                                className={cx('link')}
                                href={item.externalUrl.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {`Learn more here`}
                              </a>
                              {item.impactPdf ? (
                                <a
                                  className={cx('link')}
                                  href={item.impactPdf.pdf.file.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {`Read about our impact here`}
                                </a>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    }
                  })}
              </div>
            </div>
          </Layout>
        )
      }}
    </TransitionState>
  )
}

PartnershipsPage.propTypes = {
  data: PropTypes.object,
}

export default PartnershipsPage
