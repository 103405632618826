import PartnershipsPage from '@components/PartnershipsPage'
import { graphql } from 'gatsby'

export const query = graphql`
  query {
    contentfulPartners {
      anchor {
        url
      }
      header
      heroImage {
        description
        file {
          url
        }
      }
      members {
        image {
          description
          file {
            url
          }
        }
        name
        description {
          description
        }
        externalUrl {
          label
          url
        }
        impactPdf {
          pdf {
            file {
              url
            }
          }
        }
      }
    }
  }
`
export default PartnershipsPage
